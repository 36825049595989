import type { AxiosRequestHeaders } from "axios";
import axios from "axios";
import { acquireToken } from "@/plugins/msal"

interface APIResponse {
  email: string;
  exists: boolean;
  response: Record<string, unknown> | boolean;
}

interface Answer {
  field: string; // untranslated field key
  fieldType: "text" | "radio" | "textarea" | "checkbox" | "dropdown";
  label: string; // translated label
}

interface User {
  id: string;
  name: string;
}

interface Organisation {
  id: string;
  name: string;
}

export interface RegistrationResponse {
  _id: string;
  workFlow: "organisation" | "individual";
  language: string;
  organisation?: Organisation;
  user: User;
  answers: Answer[];
  remarks: string;
}
const BASE_URL = process.env.VUE_APP_VP_API;

enum Endpoints {
  USER = "users",
  REGISTRATION = "registrations",
}

export async function saveRegistration(
  data: Record<string, any>,
  workflow: "organisation" | "individual",
  isAuthenticated: boolean
): Promise<Record<string,string> | null> {
  if (!data || !workflow) {
    console.error("Data or workflow not set");
    return {};
  }

  try {
    if (!BASE_URL) {
      throw new Error("Base url not set, please check environment variables");
    }

    let token = null;
    if(isAuthenticated) {
      token = await acquireToken();
    }

    const headers: AxiosRequestHeaders | undefined = token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined;
    const response = await axios.post<{ id: string }>(
      `${BASE_URL}/${Endpoints.REGISTRATION}`,
      {
        registration: {
          workflow,
          ...data,
        },
      },
      {
        headers,
      }
    );

    if (!response.data.id) {
      throw new Error("ID not returned");
    }

    return {};
  } catch (e: any) {
    if (e.response) {
      return e.response.data;
    }
    if (e instanceof Error) {
      console.error(e);
      return null;
    }
  }
  return null;
}

export async function accountExists(email: string): Promise<boolean> {
  try {
    if (!BASE_URL) {
      throw new Error("Base url not set, please check environment variables");
    }
    const response = await axios.get<APIResponse>(
      `${BASE_URL}/${Endpoints.USER}?email=${email}`
    );
    return !response.data.exists;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      return true;
    }
  }
  return true;
}

export async function getRegistration(): Promise<RegistrationResponse[]> {
  try {
    if (!BASE_URL) {
      throw new Error(
        "Base url or id not set, please check environment variables and localstorage"
        );
    }
    const token = await acquireToken();
    const response = await axios.get<RegistrationResponse[]>(
      `${BASE_URL}/${Endpoints.REGISTRATION}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data ?? [];
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      return [];
    }
  }
  return [];
}

export async function deleteRegistration(
  id: string
): Promise<boolean> {
  try {
    if (!BASE_URL) {
      throw new Error("Base url not set, please check environment variables");
    }

    const token = await acquireToken();
    await axios.delete(`${BASE_URL}/${Endpoints.REGISTRATION}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return true;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e);
      return false;
    }
  }
  return false;
}

// TODO: Add using Vue patterns. (Not working)
// export const accountExists = throttle(checkUser, 1000, { leading: false, trailing: true });
