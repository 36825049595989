import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c35ae0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", {
      class: _normalizeClass({
        'w-full': true,
        'my-0 vp-label font-light': _ctx.label,
        'vp-heading-1': !_ctx.variant,
        'vp-font-medium': _ctx.variant,
        'font-bold': !_ctx.label,
        'mb-0': _ctx.subheading && !_ctx.label,
      })
    }, _toDisplayString(_ctx.heading), 3),
    (_ctx.subheading)
      ? (_openBlock(), _createElementBlock("h4", {
          key: 0,
          class: _normalizeClass({
        'w-full': true,
        'my-0 vp-label font-light': _ctx.label,
        'vp-heading-3 mt-0': !_ctx.variant,
        'vp-font-small vp-variant mt-1': _ctx.variant,
      })
        }, _toDisplayString(_ctx.subheading), 3))
      : _createCommentVNode("", true)
  ]))
}